import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Drawer, Flex, Form, Input, Row, Table, TableColumnsType, Typography } from "antd";
import { useLazyGetReportScaleQuery } from "api/sales-order/scale-api";
import { OBJ_COLOR } from "commons/constants";
import { dateFormat } from "commons/constants/util";
import dayjs from "dayjs";
import { useExportExcel } from "hooks/useExportExcel";
import moment from "moment";
import { useEffect, useState } from "react";

interface Params {
	salesOrderId?: number;
	farmCode?: string;
	customerId?: string;
	startDate?: string;
	endDate?: string;
}

interface FilterForm {
	salesOrderId?: number;
	farmCode?: string;
	customerId?: string;
	orderDate: dayjs.Dayjs[];
}

interface ReportScaleDrawerProps {
	open: boolean;
	onClose: () => void;
}

const ReportScaleDrawer = ({ open, onClose }: ReportScaleDrawerProps) => {
	const [params, setParams] = useState<Params>();

	const [form] = Form.useForm();

	const [getReportScale, { isFetching }] = useLazyGetReportScaleQuery();

	const { exportExcel } = useExportExcel();

	const [dataSource, setDataSource] = useState<ReportScale[]>([]);

	useEffect(() => {
		
		const run = async () => {
			if (!params) return;

			const data = await getReportScale(params).unwrap();
			setDataSource(data.map(item => {

				const hour = moment(item.endTimeScale).get("hour");
			   	const newEndTimeScale = hour >= 17 ? moment(item.endTimeScale).add(1, "day").format("YYYY-MM-DD") : moment(item.endTimeScale).format("YYYY-MM-DD");

			   return { ...item, endTimeScale: newEndTimeScale };
		   }));
		} 

		run();

	}, [params]);

	const handleOnFinish = (values: FilterForm) => {
		const { salesOrderId, farmCode, customerId, orderDate } = values;
		setParams({ salesOrderId, farmCode, customerId, startDate: orderDate?.[0].toISOString(), endDate: orderDate?.[1].toISOString() });
	};

	const handleReset = () => {
		form.resetFields();
		setParams({});
	};

	const columns: TableColumnsType<ReportScale> = [
		{
			title: "STT",
			dataIndex: "key",
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Mã trại</div>,
			dataIndex: "farmCode",
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Tên trại</div>,
			dataIndex: "farmName",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Phiếu cân</div>,
			dataIndex: "scaleId",
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Số PXK</div>,
			dataIndex: "transferNo",
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Mã khách hàng</div>,
			dataIndex: "customerId",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Tên khách hàng</div>,
			dataIndex: "customerName",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
		},
		{
			title: "Loại",
			dataIndex: "gender",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Số con</div>,
			dataIndex: "quantity",
			render: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Dòng gà</div>,
			dataIndex: "itemCode",
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Màu lông</div>,
			dataIndex: "itemName",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Loại gà</div>,
			dataIndex: "chickenType",
			render: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Số lồng</div>,
			dataIndex: "caseQuantity",
			render: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Trọng lượng(Kg)</div>,
			dataIndex: "weight",
			render: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>TL làm tròn(Kg)</div>,
			dataIndex: "weightRound",
			render: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>TL lồng(Kg)</div>,
			dataIndex: "caseWeightScale",
			render: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>TL trừ bì(Kg)</div>,
			dataIndex: "weightNet",
			render: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>TL bình quân lồng(Kg)</div>,
			dataIndex: "caseAverageWeight",
			render: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>TL bình quân gà(Kg)</div>,
			dataIndex: "chickenAverageWeight",
			render: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
		},
		{
			title: "Ngày",
			dataIndex: "endTimeScale",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{dateFormat(value)}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Số xe</div>,
			dataIndex: "truckId",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Tên tài xế</div>,
			dataIndex: "driver",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Địa chỉ</div>,
			dataIndex: "shipAddress",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Số lần in</div>,
			dataIndex: "printedNumber",
			render: (value) => <div style={{ textAlign: "right" }}>{value}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Ngày giờ cân</div>,
			dataIndex: "scaleDateTime",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{dateFormat(value, true)}</div>,
		},
		{
			title: <div style={{ whiteSpace: "nowrap" }}>Nhân viên cân</div>,
			dataIndex: "scaleBy",
			render: (value) => <div style={{ whiteSpace: "nowrap" }}>{value}</div>,
		},
	];

	const handleExport = () => {
		const Heading = [
			{
				farmCode: "Mã trại",
				farmName: "Tên trại",
				scaleId: "Phiếu cân",
				transferNo: "Số PXK",
				customerId: "Mã KH",
				customerName: "Tên KH",
				gender: "Loại",
				quantity: "Số con",
				itemCode: "Dòng gà",
				itemName: "Màu lông",
				chickenType: "Loại gà",
				caseQuantity: "Số lồng",
				weight: "Trọng lượng(Kg)",
				weightRound: "TL làm tròn(Kg)",
				caseWeightScale: "TL lồng(Kg)",
				weightNet: "TL trừ bì(Kg)",
				caseAverageWeight: "TL bình quân lồng",
				chickenAverageWeight: "TL bình quân gà",
				endTimeScale: "Ngày",
				truckId: "Số xe",
				driver: "Tên tài xế",
				shipAddress: "Địa chỉ",
				printedNumber: "Số lần in",
				scaleDateTime: "Ngày giờ cân",
				scaleBy: "Nhân viên cân",
			},
		];
		exportExcel({
			Heading,
			header: [],
			nameFile: "bao_cao_can_ga",
			wscols: [],
			dataExport: dataSource.map((item) => ({ ...item, endTimeScale: dateFormat(item.endTimeScale), scaleDateTime: dateFormat(item.scaleDateTime, true) })),
		});
	};

	return (
		<Drawer
			title={
				<Flex justify="space-between" align="center">
					<Typography.Title level={4} style={{ marginBottom: 0 }}>
						Báo cáo cân gà
					</Typography.Title>
					<Button onClick={handleExport} disabled={!dataSource || isFetching} type="primary" icon={<DownloadOutlined />}>
						Export
					</Button>
				</Flex>
			}
			open={open}
			width={"100%"}
			onClose={onClose}>
			<Flex vertical gap={10}>
				<Form form={form} layout="vertical" onFinish={handleOnFinish}>
					<Row gutter={[10, 10]} align="bottom">
						<Col xs={12} xl={4}>
							<Form.Item label="Sales Order Id" name="salesOrderId">
								<Input placeholder="Sales Order Id" />
							</Form.Item>
						</Col>
						<Col xs={12} xl={4}>
							<Form.Item label="Farm code" name="farmCode">
								<Input placeholder="Farm code" />
							</Form.Item>
						</Col>
						<Col xs={12} xl={4}>
							<Form.Item label="Customer Id" name="customerId">
								<Input placeholder="Customer Id" />
							</Form.Item>
						</Col>
						<Col xs={12} xl={4}>
							<Form.Item label="Order Date" name="orderDate">
								<DatePicker.RangePicker format={"DD-MM-YYYY"} style={{ width: "100%" }} />
							</Form.Item>
						</Col>
						<Col xs={12} xl={4}>
							<Form.Item>
								<Button style={{ width: "100%" }} type="primary" htmlType="submit" disabled={isFetching}>
									Search
								</Button>
							</Form.Item>
						</Col>
						<Col xs={12} xl={4}>
							<Form.Item>
								<Button onClick={handleReset} style={{ width: "100%", background: isFetching ? "#0000000a" : OBJ_COLOR.WARNING }} type="primary" disabled={isFetching}>
									Reset
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
				<Table loading={isFetching} columns={columns} dataSource={dataSource.map((item, key) => ({ ...item, key: key + 1 }))} scroll={{ x: true }} pagination={false} />
			</Flex>
		</Drawer>
	);
};

export default ReportScaleDrawer;
